







































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const form = ref<any>(null);
    const model = reactive({
      file: [] as any,
      fee: "",
      group: [],
    });

    const state = reactive({
      loading: false,
      fees: [],
      currency: "",
      groups: [],
      valid: true,
      disabledButton: true,
      error: false as boolean | number,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 406:
          return `${root.$tc("panel.event.participant.import.error406")}`;
        case 500:
          return `${root.$tc("panel.event.participant.import.error500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchFees = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/registration-fee/group`)
        .then(({ data }) => {
          state.fees = data.map((registrationFeeGroup: any) => ({
            ...registrationFeeGroup,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.fees = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchFees);

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant/group`)
        .then(({ data: { groups } }) => {
          state.groups = groups.map((degree: any, index: number) => ({
            id: index,
            ...degree,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      if (model.file) {
        const formData = new FormData();
        formData.append("file", model.file);
        formData.append("registrationFeeTermId", model.fee);
        for (let i = 0; i < model.group.length; i++) {
          formData.append("participantGroupIds[]", model.group[i]);
        }

        formData.append("eventId", root.$route.params.id);

        axiosInstance
          .post("participant/import", formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=MyBoundary",
              event: root.$route.params.id,
            },
          })
          .then(() => {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("panel.event.participant.import.importSuccess"),
            });

            model.file = "";
            model.fee = "";
            model.group = [];
            setTimeout(() => {
              goBack("panel.event.view.participant.list");
            }, 500);
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          });
      }
    };

    watch(
      () => model,
      () => {
        if (model.file && model.fee) {
          state.disabledButton = false;
        } else {
          state.disabledButton = true;
        }
      },
      { deep: true }
    );

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { form, model, state, rules, onSubmit };
  },
});
